<template>
    <front-layout>
        <p v-if="isLoading" class="load-text">загрузка...</p>
        <v-container fluid v-else-if="apartment.id" style="min-height: calc(100vh - 228px)">
            <v-container>
                <v-row>
                    <v-col xs="12" sm="12" md="7" lg="4" xl="4" cols="12" class="">
                        <v-img :src="main_img_carousel" :height="$vuetify.breakpoint.width > 900 ? 473 : 350"
                            min-width="300" class="main-img">
                        </v-img>
                        <div class="img_small">
                            <v-btn height="90" width="90" v-for="item in list_img" :key="item.name"
                                @click="main_img_carousel = '/img/' + item" class="">
                                <v-img :src="'/img/' + item" height="90" width="90"></v-img>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col class="" xs="12" sm="12" md="5" lg="8" xl="8" cols="12">
                        <div class="d-flex justify-space-between flex-wrap">
                            <h2 class="main_color_text mb-3 mb-md-0 line-height24">
                                {{ apartment.bedrooms }}-комнатная квартира, {{ apartment.area }} м<sup
                                    class="fz-14">2</sup>,
                                {{ apartment.floor }} этаж</h2>
                            <div class="main_color_text text-h5 font-weight-bold white-space-nowrap">
                                {{ price }} ₽
                            </div>
                        </div>

                        <v-list class="transparent d-block">
                            <v-list-item class="pl-0 d-flex text-left mb-3">
                                <span class="w-300">Застройщик</span>
                                <span class="main_color_text text-left">Группа "Эталон"</span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex align-baseline pt-0 mt-n6">
                                <span class="w-300">Срок сдачи</span>
                                <span class="text-left">
                                    <v-list-item class="main_color_text mb-0 mb-lg-n3 pl-0">2 квартал 2023 года корпус
                                        1этап (с.1-3, 6-8)</v-list-item>
                                    <v-list-item class="main_color_text pl-0">2 квартал 2023 года корпус 2этап (с.4,5,9)
                                    </v-list-item>
                                </span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex">
                                <span class="w-300">Тип здания</span>
                                <span class="main_color_text">монолитно-каркасный</span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex">
                                <span class="w-300">Этаж</span>
                                <span class="main_color_text white-space-nowrap">1-14</span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex">
                                <span class="w-300">Общая площадь</span>
                                <span class="main_color_text white-space-nowrap">{{ apartment.area }} м<sup
                                        class="fz-14">2</sup></span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex">
                                <span class="w-300">Жилая площадь</span>
                                <span class="main_color_text white-space-nowrap">{{ apartment.area - 1 }} м<sup
                                        class="fz-14">2</sup></span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex">
                                <span class="w-300">Площадь кухни</span>
                                <span class="main_color_text white-space-nowrap">{{
                                Math.floor(apartment.area - (apartment.area / 100 * 85))
                                }} м<sup class="fz-14">2</sup></span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex">
                                <span class="w-300">Рейтинг</span>
                                <span class="main_color_text white-space-nowrap">4.5</span>
                            </v-list-item>
                            <v-list-item class="pl-0 d-flex">
                                <span class="w-300">Тип санузла</span>
                                <span class="main_color_text white-space-nowrap">2</span>
                            </v-list-item>
                        </v-list>
                        <v-btn class="main_color_text btn-fixed-price fz-18 font-weight-bold px-2 mr-4 pl-lg-3"
                            depressed>{{ $t('headers.btn_fix_price') }}
                        </v-btn>
                        <v-btn class="main_color_text transparent mr-1 ways-to-buy fz-18 font-weight-bold px-2 pl-lg-3"
                            depressed>{{ $t('headers.btn_purchase_methods') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12" class="py-0 pr-lg-4">
                        <v-sheet class="pa-3 pa-lg-4 mb-3 mb-md-4">
                            <h3 class="mb-1 mb-lg-4 main_color_text">{{ $t('headers.how_to_buy') }}</h3>
                            <v-list-item class="pa-0 mb-n3 mb-lg-n1">ДДУ с эскроу-счетом</v-list-item>
                            <v-list-item class="pa-0 mb-n3 mb-lg-n1">Онлайн-сделка</v-list-item>
                            <v-list-item class="pa-0 mb-n3 mb-lg-n1">Trade-In</v-list-item>
                        </v-sheet>
                        <v-sheet class="main_color_text pa-4">
                            <h3 class="main_color _text mb-3 mb-md-4">{{ $t('headers.legal_documents') }}</h3>
                            <v-list-item class="pa-0 main_color_text mb-md-1 mb-0"> Проектная декларация -ից 08.04.2020
                                г.
                                <a href="#" download>PDF 632 KB</a>
                            </v-list-item>
                            <v-list-item class="pa-0 main_color_text mb-md-1 mb-0">Разрешение на строительство до
                                20.05.2023 г.
                                <a href="#" download>PDF 206 KB</a>
                            </v-list-item>
                            <v-list-item class="pa-0 main_color_text mb-md-1 mb-0">Проектная декларация -ից 07.05.2021
                                г.
                                <a href="#" download>PDF 742 KB</a>
                            </v-list-item>
                        </v-sheet>
                    </v-col>
                    <v-col xs="12" sm="12" md="12" lg="8" xl="8" cols="12" class="mt-2 mt-lg-0 pt-2 pt-lg-0 px-lg-0">
                        <v-sheet class="pa-md-4">
                            <h3 class="main_color_text mb-7">Особенности</h3>
                            <p class="line-height32">
                                Продается однокомнатная квартира в новостройке ЖК «Московские ворота II» по адресу
                                Санкт-Петербург, Московский, Малая Митрофаньевская ул., участок 46.. Общая площадь
                                квартиры - 45.6 кв. м. Тип проекта, по которому построен дом — Монолитно-каркасный,
                                компания-застройщик — Группа «Эталон». Стоимость квартиры — 9 801 220 ֏. Способы
                                оплаты можно уточнить у продавца. Срок сдачи — 2 квартал 2023. Более подробная
                                информация по телефону.
                            </p>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="transparent mt-n12 mt-md-0">
                <h2 class="main_color_text mt-10 mb-3 mb-md-0 line-height24"> {{ $t('headers.similar_apartments') }}
                </h2>
                <v-row>
                    <v-col sm="12" md="6" lg="4" xl="4" cols="12" v-if="$vuetify.breakpoint.width < 900" class="">
                        <v-carousel :show-arrows="false" height="600" hide-delimiter-background :continuous="false"
                            reverse-transition="fade-transition" transition="fade-transition"
                            class="carousel-circle align-content-stretch d-flex">
                            <v-carousel-item v-for="item in items_apartment" :key="`floor_plan`+ item[0]"
                                :active-class="'active---class'">
                                <floor-plan-card :card_data="item[0]" class="d-flex flex-column" />
                                <floor-plan-card :card_data="item[0]" class="d-flex flex-column" />
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <v-col v-else xs="12" sm="12" md="6" lg="3" xl="3" cols="12" class="mt-5 mb-5"
                        v-for="item in items.slice(0, 4)" :key="'floor-plan' + item">
                        <floor-plan-card :card_data="item" class="d-flex flex-column" />
                    </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.width > 900">
                    <v-col class="d-flex justify-center">
                        <v-btn class="transparent text-transform-none main_color_text font-weight-bold fz-18" depressed>
                            {{ $t('headers.show_more') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>

            <v-container>
                <v-row>
                    <v-col xs="12" sm="9" md="9" xl="9" lg="9" cols="12">
                        <!-- Bank offers -->
                        <v-row class="mb-4">
                            <v-col>
                                <v-row>
                                    <v-col xs="10" sm="10" md="10" xl="10" lg="10" cols="12" offset-lg="1"
                                        offset-md="1">
                                        <v-subheader class="main_color_text text-h6 font-weight-bold pl-0">
                                            {{ $t('headers.purchase_methods') }}
                                        </v-subheader>
                                    </v-col>
                                </v-row>
                                <v-form>
                                    <v-row>
                                        <v-col class="d-flex flex-wrap justify-space-between flex-lg-nowrap" xs="10"
                                            sm="10" md="10" xl="10" lg="10" cols="12" offset-lg="1" offset-md="1">
                                            <v-text-field label="ЖК Московские ворота"
                                                class="main_color_text input_text mr-0 mr-lg-3 rounded-0 w-310" solo>
                                            </v-text-field>
                                            <v-select label="Сдан"
                                                class="color-icon-main input_text mr-0 mr-lg-3 rounded-0 max-width45"
                                                id="color-icon-main" append-icon="mdi-chevron-down" solo depressed
                                                :items="items">
                                            </v-select>
                                            <v-select label="Комнатность"
                                                class="main_color_text input_text rounded-0 max-width45" solo
                                                append-icon="mdi-chevron-down" :items="items"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="d-flex mt-n3 flex-wrap justify-space-between flex-lg-nowrap"
                                            xs="10" sm="10" md="10" xl="10" lg="10" cols="12" offset-md="1"
                                            offset-lg="1">
                                            <div class="wrap_inp max-width45">
                                                <span class="subtitle_input main_color_text">Стоимость</span>
                                                <v-text-field label="Стоимость"
                                                    class="main_color_text input_text mr-0 mr-lg-3 rounded-0" solo>
                                                </v-text-field>
                                            </div>

                                            <div class="wrap_inp max-width45">
                                                <span class="subtitle_input main_color_text ">Первый взнос</span>
                                                <v-text-field label="Первый взнос"
                                                    class="main_color_text input_text mr-0 mr-lg-3 rounded-0" solo>
                                                </v-text-field>
                                            </div>

                                            <div class="wrap_inp w-310 mt-3 mt-lg-0">
                                                <span class="subtitle_input main_color_text">Срок</span>
                                                <v-text-field :value="slider_term"
                                                    class="main_color_text rounded-0 align-center slider_term_text_field"
                                                    hide-details label="Срок" solo single-line type="number">
                                                    <template v-slot:append>
                                                        <v-slider style="" :color="indigo"
                                                            class="slider_term main_color_text main_color w-310"
                                                            v-model="slider_term" :min="0" :max="30" hide-details>
                                                        </v-slider>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            class="d-flex justify-lg-end justify-space-between mt-6 mt-lg-0 align-center"
                                            xs="10" sm="10" md="10" xl="10" lg="10" cols="12" offset-md="1"
                                            offset-lg="1">
                                            <v-btn
                                                class="main_color_text transparent mr-1 text-capitalize subtitle-1 mr-2"
                                                depressed>{{ $t('headers.form_filter_btn_clear') }}
                                            </v-btn>
                                            <v-btn
                                                class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold"
                                                depressed>{{ $t('headers.form_filter_btn_apply') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                        <v-expansion-panels v-for="item in bank.slice(0, bank_qty)" :key="item.id">
                            <bank-offers-card :bank_data="item" />
                        </v-expansion-panels>
                        <v-row v-if="bank_qty <= bank.length">
                            <v-col class="d-flex justify-center mb-4">
                                <v-btn class="transparent text-transform-none main_color_text font-weight-bold fz-18"
                                    @click="bank_qty += 3" depressed>Ещё 3 предложения
                                </v-btn>
                            </v-col>
                        </v-row>

                        <h3 class="main_color_text font-weight-bold mb-4 mt-4 text-h5">{{ $t('headers.reviews') }}</h3>
                        <div v-for="review in apartment.reviews" :key="'review' + review.id">
                            <comments-card :comment_data="review" />
                        </div>

                        <h3 class="main_color_text font-weight-bold mb-4 text-h6">
                            {{ $t('headers.leave_feedback') }}</h3>
                        <comment-form @update="updateApartment" :reviewable_type="'App\\Models\\Apartment'"
                            :reviewable_id="apartment.id" />

                    </v-col>
                    <v-col xs="12" sm="3" md="3" xl="3" lg="3" cols="12" v-if="$vuetify.breakpoint.width > 1240">
                        <advertising :limit="6" />

                        <appointment-viewing-aside-card v-if="$vuetify.breakpoint.width > 1240" />
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="">
                <h2 class="main_color_text mt-3 mb-9 mb-md-0 line-height24"> {{ $t('headers.offers') }} </h2>
                <v-row class="text-center">
                    <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="$vuetify.breakpoint.width < 900"
                        class="mt-6 mt-md-0">
                        <v-carousel :show-arrows="false" height="600" hide-delimiter-background :continuous="false"
                            reverse-transition="fade-transition" transition="fade-transition"
                            class="carousel-circle align-content-stretch d-flex">
                            <v-carousel-item v-for="item in data_similar_offers_card" :key="`developer`+ item[0].id"
                                :active-class="'active---class'">
                                <similar-offers-card :project="item[0]" class="d-flex flex-column" />
                                <similar-offers-card :project="item[1]" class="d-flex flex-column" />
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <v-col v-else cols="12" sm="12" md="6" lg="3" xl="3" class="align-content-stretch d-flex"
                        v-for="item in projects.slice(0, 4)" :key="'similar-offers-card' + item.id">
                        <similar-offers-card :project="item" class="d-flex flex-column" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <v-container v-else-if="!apartment.id" style="min-height: calc(100vh - 228px)">
            <h1>Квартира не найдена!</h1>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "../../../layouts/FrontLayout";
import Apartment from "@/models/Apartment";
import FloorPlanCard from "../../../components/cards/FloorPlanCard";
import BankOffersCard from "../../../components/cards/BankOffersCard";
import CommentsCard from "../../../components/cards/CommentsCard";
import CommentForm from "../../../components/forms/CommentForm";
import AppointmentViewingAsideCard from "../../../components/cards/AppointmentViewingAsideCard";
import SimilarOffersCard from "../../../components/cards/SimilarOffersCard";
import Bank from "../../../models/Bank";
import Project from "@/models/Project";

export default {
    name: "apartments.show",
    components: {
        FrontLayout,
        FloorPlanCard,
        BankOffersCard,
        CommentsCard,
        CommentForm,
        AppointmentViewingAsideCard,
        SimilarOffersCard,
        Advertising: () => import('@/components/ads/Advertising'),
    },
    data: function () {
        return {
            apartment: [],
            items: ['Foo', 'Bar', 'Fizz', 'Buzz', 'Puzzle', 'Guzzle'],
            items_apartment: [],
            rating: null,
            main_img_carousel: '',
            list_img: ['plan.jpg', 'carousel1.jpg', 'developer_logo.png', 'giant-building.jpg'],
            price: 0,
            indigo: '#3F44C3',
            green: '#42B261',
            yellow: '#D6A700',
            slider_term: 0,
            bank: [],
            bank_qty: 3,
            data_similar_offers_card: [],
            projects: [],
            isLoading: false
        }
    },
    computed: {},
    methods: {
        async init() {
            this.isLoading = true
            let apartmentId = this.$route.params.apartment
            if (apartmentId) {
                this.apartment = await Apartment.find(apartmentId)
                this.main_img_carousel = '/img/' + this.list_img[0]
                this.price = this.apartment.price.toLocaleString('en-Es')
                this.isLoading = false
            }

            this.bank = await Bank.get()

            this.projects = await Project.get()
            this.projects = this.projects.slice(0, 10)
            let arr_sale_card = this.projects.slice(0, 10)
            this.data_similar_offers_card = this.chunkArray(arr_sale_card, 2)

            this.items_apartment = this.chunkArray(this.items, 2)
            this.isLoading = false
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        updateApartment(review) {
            this.apartment.reviews.push(review)
        },
    },
    async mounted() {
        this.init()
    },
}

</script>

<style lang="scss" scoped>

.load-text {
    text-align: center;
    color: #3998ec;
}

.line-height32 {
    line-height: 32px;
}

.w-300 {
    width: 300px;

    @media all and (max-width: 900px) {
        max-width: calc(50% - 12px);
    }
}

.fz-14 {
    font-size: 14px;
}

.d-flex.justify-space-between.v-list-item.theme--light {
    justify-content: space-between;

    &::after {
        content: none;
    }
}

.main_color_text,
.theme--light.v-list-item.main_color_text {
    color: var(--main_color-base) !important;
}

.img_small {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: -10px;
    margin-left: -10px;

    button {
        margin: 9px 0 0 9px;
    }
}

.btn-fixed-price {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    height: 50px !important;
    width: 294px;
    border-radius: 0;
    text-transform: none;

    @media all and (max-width: 900px) {
        min-width: 100%;
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}

.ways-to-buy {
    height: 50px !important;
    width: auto !important;
    border-radius: 0;
    text-transform: none;

    @media all and (max-width: 900px) {
        display: block;
        margin: 20px auto !important;
        width: 100% !important;
    }
}

.w-310 {
    width: 310px;
}

.w-156 {
    width: 156px;
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.slider_term_text_field {
    position: relative;
    height: 48px;
    color: var(--main_color-base) !important;
}

.slider_term {
    color: var(--main_color-base) !important;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 350px;
}

.wrap_inp {
    position: relative;

    .subtitle_input {
        position: absolute;
        color: white;
        top: -25px;
        left: 0;
        font-size: 16px;
    }
}

.white-space-nowrap {
    white-space: nowrap;
}

.min-width100 {
    min-width: 100%;
}

.line-height30 {
    line-height: 30px;
}

.fz-18 {
    font-size: 18px;
    letter-spacing: 0;
}
</style>
