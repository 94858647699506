<template>
    <v-main>
        <top-menu />
        <slot> <!--CONTENT--> </slot>
        <bottom-menu />
    </v-main>
</template>

<script>
    import TopMenu from "../components/TopMenu";
    import BottomMenu from "../components/BottomMenu";

    export default {
        name: "front-layout",
        components: {TopMenu, BottomMenu},
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
