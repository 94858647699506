<template>
    <v-menu offset-y class="align-end">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="d-flex justify-center px-0 btn-menu-burger" height="37" width="34"
                   v-bind="attrs"
                   v-on="on"
                   depressed>
                <v-icon v-if="$vuetify.breakpoint.width < 900" class="main_color_text px-0" x-large large>mdi-menu
                </v-icon>
                <v-icon v-else class="btn__account-circle" large>mdi-account-circle-outline</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item :to="{name: dashboardLink}">
                <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleLogout">
                <v-list-item-title class="cursor-pointer">Logout</v-list-item-title>
            </v-list-item>

            <div v-if="$vuetify.breakpoint.width < 900">
                <v-list-item :to="{name: 'projects.index'}">
                    <v-list-item-title>{{ $t('menu.projects') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name: 'banks.index'}">
                    <v-list-item-title class="cursor-pointer">{{ $t('menu.banks') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name: 'developers.index'}">
                    <v-list-item-title class="cursor-pointer">{{ $t('menu.developers') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name: 'cities.index'}">
                    <v-list-item-title class="cursor-pointer">{{ $t('menu.cities') }}</v-list-item-title>
                </v-list-item>
            </div>
            <v-list-item>
                <waiting-call-dialog :btn_location="'header'" />
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import WaitingCallDialog from "@/components/dialogs/WaitingCallDialog";

export default {
    name: "auth-user-dropdown",
    components: {WaitingCallDialog},
    computed: {
        dashboardLink() {
            return this.$auth.user().primary_role_name === 'admin' ? 'admin.index' : 'dashboard.index'
        },
        auth() {
            return this.$auth
        },
        width_site: {
            get() {
                return this.$vuetify.breakpoint.width;
            },
            set() {
                this.$store.commit("site/updateWidth_site", window.innerWidth);
            }
        },
    },
    data: function () {
        return {}
    },
    methods: {
        handleLogout() {
            this.$auth.logout()
        },
    },
    mounted() {
        this.$store.commit("site/updateWidth_site");
    }
}
</script>

<style scoped lang="scss">

.btn__account-circle {
    width: 34px !important;
    height: 34px !important;
    max-width: 34px !important;
    max-height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.btn-menu-burger {
    @media all and (max-width: 900px) {
        margin-right: -16px;
        max-width: 34px !important;
        min-width: 34px !important;
        width: 34px !important;
        overflow: hidden !important;
        display: flex !important;
        justify-content: flex-end !important;
    }
}
</style>
