import Model from './Model'

export default class Apartment extends Model {
    // Set the resource route of the model
    resource() {
        return 'apartment'
    }

    request(config) {
        return this.$http.request(config)
    }

    parameterNames() {
        return {}
    }
}
