<template>
    <v-row class="row__wrap">
        <v-col xs="col_qty" sm="col_qty" md="col_qty" :xl="col_qty" :lg="col_qty" cols="12" :offset-lg="offsetLG" :offset-md="offsetLG" class="px-0">
            <v-expansion-panel class="pl-2 pl-lg-5 mb-6 before-none-shadow">
                <v-expansion-panel-header class="pl-1 pr-2">
                    <v-row>
                        <v-col class="d-flex justify-start align-center pb-6">
                            <v-img width="100%" height="48" max-width="48" max-height="48" class="d-inline-block mr-3"
                                   v-if="bank_data.mortgages"
                                   :src="bank_data.logo_url"></v-img>
                            <a :href="'bank/'+bank_data.slug"
                               class="main_color_text font-weight-bold text-h6 text-decoration-none d-inline-block"
                            >{{ bank_data.name }}</a>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-wrap">
                        <div class="circle_statistic">
                            <v-progress-circular class="progress_circle"
                                                 :rotate="0" :size="93" :width="8" :value="50"
                                                 :color="indigo">
                            </v-progress-circular>
                            <v-progress-circular class="progress_circle"
                                                 :rotate="-180" :size="93" :width="8" :value="25"
                                                 :color="green">
                            </v-progress-circular>
                            <v-progress-circular class="progress_circle"
                                                 :rotate="-90" :size="93" :width="8" :value="25"
                                                 :color="yellow">
                            </v-progress-circular>
                        </div>

                        <v-list class="mt-2 mt-lg-n5 ml-n3 ml-lg-16 bank_offer_list mw100">
                            <v-list-item class="mb-n3">
                                <v-list-item-title>{{ $t('headers.initial_payment') }}</v-list-item-title>
                                <v-list-item-title
                                    class="font-weight-bold bank_offer_list__text-green">1,500,000 ₽
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="mb-n3">
                                <v-list-item-title>{{ $t('headers.amount_of_credit') }}</v-list-item-title>
                                <v-list-item-title
                                    class="font-weight-bold bank_offer_list__text-indigo">7,071,125 ₽
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="mb-n3">
                                <v-list-item-title>{{ $t('headers.overpayment') }}</v-list-item-title>
                                <v-list-item-title
                                    class="font-weight-bold bank_offer_list__text-yellow">1,876,754 ₽
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </div>

                </v-expansion-panel-content>
                <div class="ml-n5 z-index10 bg_bg" v-if="bank_data.mortgages[0]">
                    <div class="pt-0 pl-0 mt-2">
                        <v-simple-table class="transparent table-border-none" dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left font-weight-light">
                                            {{ $t('headers.program') }}
                                        </th>
                                        <th class="text-left font-weight-light">
                                            {{ $t('headers.bid') }}
                                        </th>
                                        <th class="text-left font-weight-light">
                                            {{ $t('headers.beginning_contribution') }}
                                        </th>
                                        <th class="text-left font-weight-light">
                                            {{ $t('headers.credit_term') }}
                                        </th>
                                        <th class="text-left font-weight-light" v-if="$vuetify.breakpoint.width > 900">
                                            {{ $t('headers.payment_per_month') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="i in bank_data.mortgages" :key="i.id">
                                        <td class="main_color_text font-weight-bold">{{ i.name }}</td>
                                        <td class="main_color_text font-weight-bold">{{ i.min_rate }}</td>
                                        <td class="main_color_text font-weight-bold">
                                            -ից {{ i.percentage_initial_payment }}%
                                        </td>
                                        <td class="main_color_text font-weight-bold">до 30 лет</td>
                                        <td class="main_color_text font-weight-bold" v-if="$vuetify.breakpoint.width > 900">56 743 ֏</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-expansion-panel>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "bank-offers-card",
    props: ['bank_data', 'parent_component'],
    data: function () {
        return {
            indigo: '#3F44C3',
            green: '#42B261',
            yellow: '#D6A700',
            items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
            slider_term: 0,
            offsetLG: 1,
            col_qty: 10,
        }
    },
    methods: {
        async init() {
            if (this.parent_component === 'banks_index') {
                this.offsetLG = 0
                this.col_qty = 12
            }
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped lang="scss">

.bank_offer_list {
    width: 400px;
    max-width: 400px;

    .bank_offer_list__text-indigo {
        color: var(--main_color-base);
    }

    .bank_offer_list__text-green {
        color: var(--green_color-base);
    }

    .bank_offer_list__text-yellow {
        color: var(--yellow_color-base);
    }
}

.circle_statistic {
    width: 95px;
    height: 95px;
    position: relative;

    .progress_circle {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.input_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
}

.w-310 {
    width: 310px;
}

.w-156 {
    width: 156px;
}

.fz-18 {
    font-size: 18px;
    letter-spacing: 0;
}

.fz-14 {
    font-size: 14px;
    letter-spacing: 0;
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.slider_term_text_field {
    position: relative;
    height: 48px;
    color: var(--main_color-base) !important;
}

.slider_term {
    color: var(--main_color-base) !important;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 350px;
}

.before-none-shadow {
    &::before {
        display: none;
    }
}

.mw100 {

    @media all and (max-width: 1240px) {
        min-width: 100%;
    }
}

.bg_bg {
    background: var(--bg);
}

.row__wrap {
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
</style>
