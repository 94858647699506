<template>
    <v-container fluid id="footer-container--fluid">
        <v-container id="footer-container">
            <v-footer
                color="primary"
                padless
                id="footer-footer">
                <v-row class="mw100">
                    <v-col xs="12" sm="12" md="12" xl="3" lg="3" cols="12" class="pa-0">
                        <router-link :to="{name: 'home'}" class="link-logo">
                            <div class="d-flex align-center justify-center justify-lg-start mb-5 mb-lg-0 ml-n5 ml-lg-0">
                                <v-img
                                    :alt="appName + 'logo'"
                                    class="shrink mr-2"
                                    contain
                                    :src="require('../assets/img/logo.png')"
                                    transition="scale-transition"
                                    width="180"
                                    style="z-index: 200"/>
                            </div>
                        </router-link>
                    </v-col>

                    <v-col xs="12" sm="12" md="12" xl="9" lg="9" cols="12"
                           class="align-lg-end flex-lg-row justify-lg-end d-lg-flex pl-0 d-flex flex-column align-center mb-5 mb-lg-0">
                        <div class="ml-lg-6 ml-sm-0 px-0 white_color_text transparent min-height40 d-flex align-center">
                            <waiting-call-dialog :btn_location="'footer'" class="px-0" />
                        </div>
                        <v-btn plain :to="{name: 'projects.index'}" class="ml-lg-6 ml-sm-0 px-0 white_color_text transparent min-height40" depressed>
                            {{ $t('menu.projects') }}
                        </v-btn>
                        <v-btn plain :to="{name: 'banks.index'}" class="ml-lg-6 ml-sm-0 pl-0 px-0 white_color_text transparent min-width min-height40" depressed>
                            {{ $t('menu.banks') }}
                        </v-btn>
                        <v-btn plain :to="{name: 'developers.index'}" class="ml-lg-6 ml-sm-0 px-0 white_color_text transparent min-height40" depressed>
                            {{ $t('menu.developers') }}
                        </v-btn>
                        <v-btn plain :to="{name: 'blogs.index'}" class="ml-lg-6 ml-sm-0 px-0 white_color_text transparent min-height40" depressed>
                            {{ $t('menu.blogs') }}
                        </v-btn>
                        <v-btn plain :to="{name: 'cities.index'}" class="ml-lg-6 ml-sm-0 px-0 white_color_text transparent min-height40" depressed>
                            {{ $t('menu.cities') }}
                        </v-btn>
                        <v-btn plain :to="{name: 'banks.index'}" class="ml-lg-6 ml-sm-0 px-0 white_color_text transparent min-height40" depressed>
                            {{ $t('menu.terms_of_use') }}
                        </v-btn>
                        <v-btn plain :to="{name: 'about'}" class="ml-lg-6 ml-sm-0 px-0 white_color_text transparent min-height40" depressed>
                            {{ $t('menu.about_the_project') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row d-flex align="end">
                    <v-col xs="12" sm="12" md="12" xl="2" lg="2" cols="12"
                           class="font-weight-medium subtitle-2 pl-0 copyright text-center text-lg-left order-2 order-lg-1 min-height60"
                    >© 2005—{{ new Date().getFullYear() }}, «{{ appName }}»
                    </v-col>
                    <v-col xs="12" sm="12" md="12" xl="3" lg="3" cols="12" offset-sm="0" offset-lg="7"
                           class="justify-lg-end d-flex justify-center pl-lg-6 pl-sm-01 order-1 order-lg-1">
                        <a href="//instagram.com" target="_blank">
                            <v-img :src="require('../assets/img/icons/instagram.png')" width="32" height="32"></v-img>
                        </a>
                        <a href="//youtube.com" target="_blank" class="ml-6">
                            <v-img :src="require('../assets/img/icons/youtube.png')" width="32" height="32"></v-img>
                        </a>
                        <a href="//twitter.com" target="_blank" class="ml-6">
                            <v-img :src="require('../assets/img/icons/twitter.png')" width="32" height="32"></v-img>
                        </a>
                        <a href="//linkedin.com" target="_blank" class="ml-6">
                            <v-img :src="require('../assets/img/icons/linkedin.png')" width="32" height="32"></v-img>
                        </a>
                        <a href="//tiktok.com" target="_blank" class="ml-6">
                            <v-img :src="require('../assets/img/icons/tiktok.png')" width="32" height="32"></v-img>
                        </a>
                    </v-col>
                </v-row>
            </v-footer>
        </v-container>
    </v-container>
</template>

<script>
import WaitingCallDialog from "@/components/dialogs/WaitingCallDialog";

export default {
    name: "bottom-menu",
    components: {WaitingCallDialog},
    props: {
        isDashboard: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        appName() {
            return process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "GETHOME"
        }
    },
    data: function () {
        return {
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
#footer-container--fluid,
#footer-container,
#footer-footer {
    background: var(--main_dark_color-base) !important;
}

#footer-container--fluid {
    position: relative;
    z-index: 10;
}

#logo-name,
#logo-name-subtitle {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
    color: var(--white_color-base);
}

#logo-name-subtitle {
    font-weight: 400;
}

.link-logo {
    text-decoration: none;
}

.copyright {
    color: #ffffff;
    white-space: nowrap;
}

.mw100 {
    min-width: 100%;
}

.min-width {
    min-width: 0 !important;
}

.min-height40 {
    min-height: 40px;
}
</style>
