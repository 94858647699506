<template>
    <v-row>
        <v-col cols="auto rounded-0" class="px-0">
            <v-dialog
                transition="dialog-top-transition"
                :max-height="$vuetify.breakpoint.width > 900 ? 500 : 620"
                :max-width="$vuetify.breakpoint.width > 900 ? 820 : 395"
                class="p-relative overflow-hidden rounded-0"
                v-model="dialogState"
            >
                <template v-slot:activator="binds">
                    <slot name="activator" v-bind="binds" />
                </template>
                <template v-slot:default="dialog">
                    <v-card class="d-block d-md-flex p-relative overflow-hidden" rounded="0">
                        <v-img src="/img/auth_modal.jpg"
                               :max-height="$vuetify.breakpoint.width > 900 ? '100%' : 133"
                               :max-width="$vuetify.breakpoint.width > 900 ? 315 : 395"
                               class="border-radius0"
                        ></v-img>
                        <div class="dialog-content">
                            <v-btn text @click="dialog.value = false" id="close" max-height="30" max-width="30">
                                &times;
                            </v-btn>
                            <div>
                                <v-img
                                    :alt="appName + 'logo'"
                                    class="shrink mb-8"
                                    contain
                                    src="/img/logo.png"
                                    transition="scale-transition"
                                    width="180"/>

                                <!--    Login    -->
                                <p class="dialog-p" v-if="show_login">
                                    <span class="text-bold">{{ $t('auth.login1') }}</span>
                                    {{ $t('auth.login_title') }}
                                </p>
                                <v-form ref="form" id="form__loginDialog" v-if="show_login">
                                    <v-text-field :error-messages="errors.email" class="text-input mb-8 transparent"
                                                  :label="$t('auth.email')"
                                                  :rules="rules_email"
                                                  type="email" solo
                                                  height="47"
                                                  max-height="47"
                                                  width="360"
                                                  @input="btn_active()"
                                                  v-model="email"/>
                                    <v-text-field :error-messages="errors.password" class="text-input mb-8 transparent"
                                                  :label="$t('auth.password')"
                                                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :rules="rules_password" solo
                                                  :type="show_password ? 'text' : 'password'"
                                                  height="47" max-height="47" width="360"
                                                  @input="btn_active()"
                                                  v-model="password"
                                                  @click:append="show_password = !show_password"/>
                                    <v-btn class="btn-submit" @click="handleLogin" :disabled="!btn_disabled">{{ $t('auth.enter') }}</v-btn>
                                    <v-btn class="sign_up_link transparent mt-6 mt-md-14" depressed
                                           @click="show_login = !show_login">{{ $t('auth.sign_up') }}
                                    </v-btn>
                                </v-form>

                                <!--    Sign Up    -->
                                <p class="dialog-p" v-if="!show_login">
                                    <span class="text-bold">{{ $t('auth.sign_up1') }}</span>
                                    {{ $t('auth.login_title') }}
                                </p>
                                <v-form ref="form" id="form__signUpDialog" v-if="!show_login">
                                    <v-text-field :error-messages="errors.phone" class="text-input mb-8 transparent"
                                                  :label="$t('auth.phone_number')"
                                                  :rules="rules_phone" solo
                                                  height="47"
                                                  max-height="47"
                                                  width="360"
                                                  @input="btn_active()"
                                                  v-model="phone"/>
                                    <v-text-field :error-messages="errors.email" class="text-input mb-8 transparent"
                                                  :label="$t('auth.email')"
                                                  :rules="rules_email"
                                                  type="email" solo
                                                  height="47"
                                                  max-height="47"
                                                  width="360"
                                                  @input="btn_active()"
                                                  v-model="email"/>
                                    <v-text-field :error-messages="errors.password" class="text-input mb-8 transparent"
                                                  :label="$t('auth.password')"
                                                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :rules="rules_password" solo
                                                  :type="show_password ? 'text' : 'password'"
                                                  height="47" max-height="47" width="360"
                                                  @input="btn_active()"
                                                  v-model="password"
                                                  @click:append="show_password = !show_password"/>
                                    <v-text-field class="text-input mb-8 transparent"
                                                  height="47" max-height="47" width="360"
                                                  :error-messages="errors.password_confirmation"
                                                  :append-icon="show_password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :type="show_password_confirmation ? 'text' : 'password'"
                                                  :label="$t('auth.password')"
                                                  v-model="password_confirmation"
                                                  :rules="rules_password_confirm" solo
                                                  @input="btn_active()"
                                                  @click:append="show_password_confirmation = !show_password_confirmation"/>
                                    <v-btn class="btn-submit" @click="handleSignUp" :disabled="!btn_disabled">
                                        {{ $t('auth.sign_up') }}
                                    </v-btn>
                                    <v-checkbox
                                        v-model="check_privacy_policy"
                                        class="mt-2"
                                        color="violet">
                                        <template v-slot:label>
                                            <div id="checkbox_text">{{ $t('auth.agree') }}</div>
                                        </template>
                                    </v-checkbox>
                                    <v-btn class="login_link transparent plain" color="transparent"
                                           depressed @click="show_login = !show_login">{{ $t('auth.login') }}
                                    </v-btn>
                                </v-form>
                            </div>
                        </div>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "WaitingCallDialog",
    props: ['btn_location', 'value', 'show_form_login'],
    data: function () {
        return {
            show_login: this.show_form_login,
            text: 'Are you sure?',
            phone: '',
            email: '',
            password: '',
            password_confirmation: '',
            show_password: false,
            show_password_confirmation: false,
            rules_password: [
                v => !!v || 'Required.',
                v => v.length >= 6 || 'Min 6 characters',
            ],
            rules_password_confirm: [
                v => this.password === v || 'Password mismatch.',
            ],
            rules_email: [
                v => !!v || 'Required.',
                v => (v && v.length >= 6) || 'Min 6 characters',
                v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Invalid e-mail.'
                },
            ],
            rules_phone: [
                v => !!v || 'Required.',
                v => v.length >= 7 || 'Min 7 characters',
                v => {
                    const pattern = /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/
                    return pattern.test(v) || 'Invalid phone number.'
                },
            ],
            errors: {},
            btn_disabled: false,
            check_privacy_policy: false,
        }
    },
    methods: {
        async handleLogin() {
            const {email, password} = this;
            if (this.validate()) {
                this.$auth.login({data: {email, password}, redirect: null})
                    .then(res => {
                        let route = res.data.data.roles.findIndex(role => role === 'admin') > -1
                            ? this.$auth.options.loginData.redirect.admin
                            : this.$auth.options.loginData.redirect.other

                        this.$router.push(route)
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                    })
            }
        },
        async handleSignUp() {
            const {email, password, password_confirmation} = this;
            if (this.validate()) {
                await this.$auth.register({
                    data: {email, password, password_confirmation},
                    success() {
                        this.$auth.ready();
                    },
                    staySignedIn: true,
                    redirect: {name: 'dashboard.index'},

                }).catch(e => this.errors = e.response.data.errors)
            }
        },
        validate() {
            return this.$refs.form.validate()
        },
        btn_active() {
            this.btn_disabled = this.validate()
        }
    },
    computed: {
        appName() {
            return process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "GETHOME"
        },
        dialogState: {
            get(){
                return !!this.value
            },
            set(v){
                this.$emit('input', !!v);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
#border-btn {
    border: 1px solid var(--main_color-base) !important;
    border-radius: 0;
    color: var(--main_color-base);

    @media all and (max-width: 900px) {
        border: none !important;
        min-width: 36px;
        padding: 0;
        overflow: hidden;
    }
}

#border-btn__footer {
    background: transparent;
    color: white !important;
}

.max-height500 {
    max-height: 500px;
}

#close {
    max-width: 30px !important;
    max-height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    top: 5px;
    right: 10px;
    font-weight: bold;
    font-size: 24px;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 50%;
}

.border-radius0 {
    border-radius: 0 !important;
}

.dialog-content {
    margin: 20px 40px;

    @media all and (max-width: 900px) {
        margin: 10px 20px;
    }

    .dialog-p {
        max-width: 370px;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        color: #000000;

        .text-bold {
            font-weight: bold;
        }
    }
}

#form__signUpDialog,
#form__loginDialog {
    max-width: 100%;

    .text-input {
        border: 1px solid var(--main_color-base) !important;
        border-radius: 0;
        position: relative !important;
        max-height: 47px !important;
        height: 47px !important;
        width: 360px !important;

        .v-input__control {
            .v-input__slot {
                background: transparent !important;
            }
        }

        .v-text-field__details {
            position: absolute !important;
            bottom: -28px !important;
            background: transparent !important;
        }
    }

    .btn-submit {
        background: var(--main_color-base) !important;
        border: 1px solid var(--main_color-base) !important;
        box-sizing: border-box !important;
        border-radius: 0 !important;
        width: 360px !important;
        max-width: 100%;
        height: 47px !important;
        color: white !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0;
        text-transform: none;
    }
}

.time_to_call {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: rgba(63, 68, 196, 0.57) !important;
    text-transform: none;
    max-width: 100%;
}

.border-bottom {
    border-bottom: dashed;
    border-bottom-width: 1px;
    color: rgba(63, 68, 196, 0.57) !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

#checkbox_text,
.text_show_message {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    color: rgba(63, 68, 196, 0.57) !important;
    text-transform: none !important;
    display: inline-block !important;
    max-width: 100%;
}

.sign_up_link,
.login_link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    min-width: 100% !important;
    display: block;
    color: var(--main_color-base) !important;
    text-transform: none;
    text-align: center;
    background: transparent !important;
}
</style>
