<template>
    <v-card class="d-flex flex-column justify-center align-center pa-3" elevation="0">
        <template v-if="project.developer">
            <v-card-title v-if="project.developer.name" class="text-h6 pa-0 align-self-start mb-2">
                {{ project.developer.name }}
            </v-card-title>
            <v-card-title v-if="project.developer.phone" class="pa-0 subtitle-1 d-block align-self-start mb-2">
                {{ project.developer.phone }}
            </v-card-title>
            <v-card-title v-if="project.developer.working_time" class="pa-0 subtitle-1 mt-n2 d-block align-self-start">
                {{ project.developer.working_time }}
            </v-card-title>
        </template>

        <v-btn class="main_color_text btn_aside_action subtitle-1 font-weight-bold mt-2 mx-n2 text-transform-none" depressed>
            {{$t('headers.btn_appointment_for_viewing')}}
        </v-btn>

        <a href="#" class="main_color_text font-weight-bold mb-10 mt-2 text-decoration-none">
            {{ $t('headers.btn_online_deal') }}
        </a>

        <v-btn class="main_color_text btn_aside_action subtitle-1 font-weight-bold mt-16 text-transform-none" depressed>
            {{$t('headers.btn_chat_with_consultant')}}
        </v-btn>

        <v-card-text v-if="project.short_description" class="grey--text fz-14 pa-0 text-center mt-2">
            {{ project.short_description }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "appointment-viewing-aside-card",
    props: ['project'],
    data: function () {
        return {}
    },
}
</script>

<style scoped lang="scss">
.btn_aside_action {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 50px !important;
    border-radius: 0;

    @media all and (max-width: 1240px) {
        font-size: 13px !important;
        height: 40px !important;
    }
}

.fz-14 {
    font-size: 14px;
    line-height: 17px;

    @media all and (max-width: 1240px) {
        font-size: 13px;
        line-height: 15px;
    }
}
</style>
