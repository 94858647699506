<template>
    <v-card elevation="0" class="mr-4 mr-md-0 min-width100 min-width51 min-height700" color="white">
        <a :href="$router.resolve({name: 'projects.show', params: {project: this.project.slug}}).href"
                    @click.prevent="goToPage(project.slug)"
                     class="text-decoration-none d-flex flex-column align-center justify-space-between bg_white min-width100"
                     style="height: 100%;">
            <v-img :src="project.image_url" class="text-right card-img-plan pb-2 p-relative">
                <div class="wrap_statistic_similaroffers">
                    <v-icon>mdi-star</v-icon>
                    4.5
                    <v-icon class="ml-2">mdi-message-processing</v-icon>
                    11
                </div>
                <v-btn class="mt-2 mr-2 btn-phone pa-0 bg_white" width="38" height="38" max-height="38" max-width="38">
                    <v-icon class="main_color_text">mdi-phone</v-icon>
                </v-btn>
                <v-btn class="mt-2 mr-2 btn-percent pa-0" width="38" height="38" max-height="38" max-width="38">
                    <v-icon class="color-icon-white">mdi-percent-outline</v-icon>
                </v-btn>
            </v-img>
            <v-list class="bg_white list-item-del-after min-width100">
                <v-list-item class="d-flex justify-space-between mt-n4 pa-1" style="max-height: 15px">
                    <span class="grey--text font-weight-thin subtitle-2" v-if="project.developer">{{
                            project.developer.name }}</span>
                    <span class="green--text font-weight-thin subtitle-2">Строится</span>
                    <span class="yellow--text font-weight-thin subtitle-2" v-if="false">II кв 2023</span>
                </v-list-item>
                <v-list-item class="card-plan-first-line pa-1 text-h5 font-weight-thin main_color_text mt-n4">
                    <div v-if="project.name" class="products-card__title">{{ project.name }}</div>
                </v-list-item>
                <v-list-item class="pa-1 font-weight-thin mt-n1 subtitle-2 text--black text-left" id="text--black">
                    {{ address }}
                </v-list-item>

                <!--                <div v-for="apartment of project['apartments'].slice(0, 4)" :key="apartment.id">-->
                <!--                    <v-list-item class="pa-1 d-flex justify-space-between card-plan_bg-violet mt-1">-->
                <!--                        <span class="fz-14 text&#45;&#45;black">{{ apartment.bedrooms }}-комн.-->
                <!--                            <span class="grey&#45;&#45;text fz-14">-ից {{ apartment.area }} м<sup-->
                <!--                                    class="fz-10">2</sup></span></span>-->
                <!--                        <span class="fz-14 text&#45;&#45;black">-ից {{ apartment.price ? formatterPrice(apartment.price) : apartment.price }} ֏</span>-->
                <!--                    </v-list-item>-->
                <!--                </div>-->


                <!--                <v-list-item class="pa-1 d-flex justify-space-between card-plan_bg-violet mt-1">
                                    <span class="fz-14 text&#45;&#45;black">1-комн. <span class="grey&#45;&#45;text fz-14">от 37.90 м<sup
                                        class="fz-10">2</sup></span></span>
                    <span class="fz-14 text&#45;&#45;black">от 11 104 070 Р</span>
                </v-list-item>
                <v-list-item class="pa-1 d-flex justify-space-between card-plan_bg-violet mt-1">
                                    <span class="fz-14 text&#45;&#45;black">2-комн. <span class="grey&#45;&#45;text fz-14">от 55.80 м<sup
                                        class="fz-10">2</sup></span></span>
                    <span class="fz-14 text&#45;&#45;black">от 15 837 800 Р</span>
                </v-list-item>
                <v-list-item class="pa-1 d-flex justify-space-between card-plan_bg-violet mt-1">
                                    <span class="fz-14 text&#45;&#45;black">3-комн. <span class="grey&#45;&#45;text fz-14">от 81.30 м<sup
                                        class="fz-10">2</sup></span></span>
                    <span class="fz-14 text&#45;&#45;black">от 18 529 010 Р</span>
                </v-list-item>
                <v-list-item class="pa-1 d-flex justify-space-between card-plan_bg-violet mt-1">
                                    <span class="fz-14 text&#45;&#45;black">14-комн. <span class="grey&#45;&#45;text fz-14">от 121.80 м<sup
                                        class="fz-10">2</sup></span></span>
                    <span class="fz-14 text&#45;&#45;black">от 27 837 430 Р</span>
                </v-list-item>
                <v-list-item class="pa-1 d-flex justify-space-between mt-1">
                    <span class="fz-14 font-weight-bold main_color_text">123 квартиры</span>
                    <span class="fz-14 main_color_text">от 58 800 ֏/мес</span>
                </v-list-item>-->
            </v-list>
        </a>
    </v-card>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";

export default {
    name: "similar-offers-card",
    props: ['project'],
    data () {
        return {
            pr: [],
            proj: [],
            address: ''
        }
    },
    methods: {
        async goToPage(slug) {
            await this.$router.push({name: 'projects.show', params: {project: this.project.slug}})
            this.$emit('change', slug);
        },

        formatterPrice(price) {
            return price.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
        },

        init() {
            axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.project.latitude}&lon=${this.project.longitude}&accept-language=${i18n.locale}`, {
                withCredentials: false
            })
                .then(response => {
                    this.address = response.data.display_name
                })
                .catch(() => {
                    this.address = ""
                })
            //console.log(typeof this.project['apartments'])
            /* Number of room ascending */
            /*for (let i in this.project['apartments']) {
                this.proj.push(i.sort((a, b) => a['bedrooms'] - b['bedrooms']));
            }*/

            /*this.project.forEach(el => {
                /!*console.log(typeof el)
                console.log(el + '<-------')*!/
                this.proj.push(el.sort((a, b) => a['bedrooms'] - b['bedrooms']));
            })*/

            /*this.pr = this.proj.filter((el) => {
                return el['apartments'].sort((a, b) => a['bedrooms'] - b['bedrooms']);
            })*/
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped lang="scss">

.products-card {
    &__title {
        text-align: left;
        word-break: break-word;
    }
}
.card-plan_bg-violet {
    background: rgba(63, 68, 196, 0.1);
    min-height: 37px !important;
    padding: 0 4px !important;
}

.w-100 {
    width: 100% !important;
}

.wrap_statistic_similaroffers {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(50, 50, 50, .7);
    padding: 3px;
    color: white;

    i {
        color: white !important;
    }

    @media all and (max-width: 900px) {
        top: 15px;
        left: 7px;
    }
}

.btn-percent {
    position: absolute;
    background: var(--green_color-base) !important;
    bottom: 10px;
    right: 0;
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.color-icon-white {
    color: white !important;
}

.btn-phone,
.btn-favorite {
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.btn-phone {
    position: absolute;
    right: 0;
    top: 7px;
}

.d-flex.justify-space-between.v-list-item.theme--light {
    justify-content: space-between;

    &::after {
        content: none;
    }
}

.fz-14 {
    font-size: 14px;
    letter-spacing: 0;
}

.fz-10 {
    font-size: 10px;
    letter-spacing: 0;
}

.carousel-circle .v-carousel--hide-delimiter-background .v-carousel__controls {
    @media all and (max-width: 900px) {
        background: whitesmoke !important;
    }
}

.min-width100 {
    min-width: 100%;
}

.min-width51 {
    @media all and (max-width: 900px) {
        min-width: 51% !important;
    }
}

.min-height700 {
    @media all and (max-width: 900px) {
        min-height: 700px;
    }
}

.v-application .white {
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;

    @media all and (max-width: 900px) {
        background-color: var(--bg) !important;
        border-color: var(--bg) !important;
    }
}

.bg_white {
    background: #ffffff !important;
}

#text--black,
.text--black {
    color: black !important;
}

.list-item-del-after {
    .v-list-item {
        &:after {
            display: none;
        }
    }
}

.card-img-plan {
    min-width: 100%;
    min-height: 200px;
    position: relative;
}
</style>
