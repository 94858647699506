<template>
    <v-form class="bg_white pa-4 pa-md-6" ref="form">
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                   class="justify-space-between d-flex flex-nowrap">
                <v-text-field :label="$t('headers.form_your_name')"
                              class="main_color_text input_review filter-total-area rounded-0 mr-2 mb-sm-0 mb-3"
                              solo></v-text-field>
                <v-text-field :label="$t('headers.form_your_email')"
                              class="main_color_text input_review filter-total-area rounded-0 mb-sm-0 mb-3"
                              solo></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                   class="justify-space-between align-baseline mt-n6 d-block d-sm-flex">
                <v-text-field :label="$t('headers.form_your_surname')"
                              class="main_color_text input_review filter-total-area rounded-0 mr-2 mb-sm-0 mb-3 min-width382"
                              solo></v-text-field>
                <div
                    class="d-flex align-baseline justify-space-between raiting-form-review">
                    <span>{{ $t(`headers.${formType}form_evaluation`) }}</span>
                    <v-rating
                        class="ma-0 ma-sm-6 pl-2 mb-3 mb-sm-6"
                        v-model="review.rating"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        half-increments hover large
                    ></v-rating>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                   class="justify-space-between mt-n6 d-block d-sm-flex">
                <v-text-field
                                 v-model="review.advantages"
                                :label="$t(`headers.${formType}form_advantages`)"
                              class="main_color_text input_review filter-total-area rounded-0 mr-0 mr-sm-2 mb-sm-0 mb-3"
                              solo></v-text-field>
                <v-text-field v-model="review.flaws"
                                :label="$t(`headers.${formType}form_flaws`)"
                              class="main_color_text input_review filter-total-area rounded-0"
                              solo></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                   class="d-flex justify-space-between">
                <v-textarea v-model="review.text"
                            :label="$t('headers.review')" style="min-width: 100%"
                            :rules="textRules"
                            class="main_color_text textarea_review filter-total-area rounded-0 pa-0"
                            solo></v-textarea>
            </v-col>
        </v-row>

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="d-flex justify-end pr-3">

                <input type="hidden" ref="data"  :id="reviewable_id">
                <v-btn
                    @click="handleSave(reviewable_type)"
                    class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold"
                    depressed>{{$t('headers.form_btn_send')}}
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Review from "../../models/Review";

export default {
    name: "CommentForm",
    components: {},
    props: [
        'comment_data',
        'reviewable_id',
        'reviewable_type',
    ],
    computed: {
        formType(){
            return this.reviewable_type === 'App\\Models\\Bank' ? 'comment_' : ''
        }
    },
    data: function () {
        return {
            review: {
                rating: null
            },
            textRules: [
                v => !!v || 'Review text is required',
            ],
        }
    },
    mounted(){

    },
    methods: {
        async handleSave(reviewable_type) {
            this.formValid = this.$refs.form.validate()
            if (this.formValid) {
                this.review['reviewable_type'] = reviewable_type;
                this.review['reviewable_id'] = this.reviewable_id;

                if (this.$auth.user() !== null)
                    this.review['user_id'] = this.$auth.user().id;
                else
                    this.review['user_id'] = 0;

                let review = new Review(this.review)
                review = await review.save()
                this.$refs.form.reset()
                this.$emit('update', review)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.input_review {
    max-width: 410px;
    max-height: 52px;
    border: 1px solid var(--main_color-base);

    @media all and (max-width: 770px) {
        max-width: 100%;
    }
}

.textarea_review {
    max-width: 430px;
    border: 1px solid var(--main_color-base);
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.bg_white {
    background: #ffffff !important;
}

.raiting-form-review {
    width: 408px;
    max-width: 100%;
    @media all and (max-width: 770px) {
        width: 100%;
        max-width: 100%;
    }
}

.min-width382 {
    min-width: 395px;
    max-width: 410px;

    @media all and (max-width: 1240px) {
        min-width: 49.5%;
    }

    @media all and (max-width: 770px) {
        min-width: 100%;
        max-width: 100%;
    }
}
</style>
