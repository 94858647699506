<template>
    <v-card elevation="0" class="mr-4 mr-md-0 min-width100 min-width51 min-height500" color="white">
        <div class="text-decoration-none d-flex flex-column align-center justify-space-between bg_white min-width100"
            style="height: 100%;">
            <v-img src="/img/plan.jpg" class="text-right pb-2 text-right card-img-plan pb-2 p-relative">
                <v-btn class="transparent mt-4 mr-4 btn-favorite-violet" depressed>
                    <v-icon large class="color-icon-white">mdi-heart-outline</v-icon>
                    <v-icon large class="color-icon-white" v-if="false">mdi-heart</v-icon>
                </v-btn>
                <v-btn class="mt-4 mr-4 btn-phone pa-0" width="38" height="38" max-height="38" max-width="38">
                    <v-icon class="main_color_text">mdi-phone</v-icon>
                </v-btn>
                <v-btn class="mt-4 mr-4 btn-percent pa-0" width="38" height="38" max-height="38" max-width="38">
                    <v-icon class="color-icon-white">mdi-percent-outline</v-icon>
                </v-btn>
            </v-img>
            <v-list class="bg_white list-item-del-after min-width100">
                <router-link :to="`/apartment/${1}`" class="apartment-link">
                    <v-list-item class="d-flex card-plan-first-line pa-0">
                        <span class="main_color_text">Студия, 39.50 м<sup class="fz-10">2</sup></span>
                        <span class="main_color_text font-weight-bold">9 724 657 ֏</span>
                    </v-list-item>
                </router-link>
                <v-list-item class="d-flex justify-space-between card-plan_bg-violet mt-1">
                    <span class="black--text">Цена за м<sup class="fz-10">2</sup></span>
                    <span class="black--text">245 525 ֏</span>
                </v-list-item>
                <v-list-item class="d-flex justify-space-between card-plan_bg-violet mt-1">
                    <span class="black--text">Корпус</span>
                    <span class="black--text">2 этап</span>
                </v-list-item>
                <v-list-item class="d-flex justify-space-between card-plan_bg-violet mt-1">
                    <span class="black--text">Этаж</span>
                    <span class="black--text">1 из 14</span>
                </v-list-item>
                <v-list-item class="d-flex justify-space-between card-plan_bg-violet mt-1">
                    <span class="black--text">Отделка</span>
                    <span class="black--text">нет</span>
                </v-list-item>
                <v-list-item class="d-flex justify-space-between card-plan_bg-violet mt-1">
                    <span class="black--text">Ипотека</span>
                    <span class="black--text">-ից 32 229 ֏/month</span>
                </v-list-item>
            </v-list>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "floor-plan-card",
    props: ['card_data'],
    filters: {}
}
</script>

<style scoped lang="scss">

.apartment-link {
    text-decoration: none;
}

.card-plan_bg-violet {
    background: rgba(63, 68, 196, 0.1);
    min-height: 37px !important;
    padding: 0 4px !important;
}
.btn-favorite-violet {
    background: var(--main_color-base) !important;
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
    position: absolute;
    top: 10px;
    left: 10px;
}

.w-100 {
    width: 100% !important;
}

.wrap_statistic_similaroffers {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(50, 50, 50, .7);
    padding: 3px;
    color: white;

    i {
        color: white !important;
    }
}

.btn-percent {
    position: absolute;
    background: var(--green_color-base) !important;
    bottom: 10px;
    right: 0;
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.color-icon-white {
    color: white !important;
}

.btn-phone,
.btn-favorite {
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.d-flex.justify-space-between.v-list-item.theme--light {
    justify-content: space-between;

    &::after {
        content: none;
    }
}

.fz-10 {
    font-size: 10px !important;
    letter-spacing: 0;
}

.card-plan-first-line {
    font-size: 18px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    display: flex;
}

.card-plan-first-line,
.d-flex.justify-space-between.v-list-item.theme--light {
    justify-content: space-between;

    &::after {
        content: none;
    }
}

.min-width100 {
    min-width: 100%;
}

.card-img-plan {
    min-width: 100%;
    min-height: 200px;
    position: relative;
}

.bg_white {
    background: #ffffff !important;
}

#text--black,
.text--black {
    color: black !important;
}

.list-item-del-after {
    .v-list-item {
        &:after {
            display: none;
        }
    }
}

.min-width100 {
    min-width: 100%;
}

.min-width51 {
    @media all and (max-width: 900px) {
        min-width: 51% !important;
    }
}

.min-height500 {
    @media all and (max-width: 900px) {
        min-height: 500px;
    }
}

.v-application .white {
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;

    @media all and (max-width: 900px) {
        background-color: var(--bg) !important;
        border-color: var(--bg) !important;
    }
}

.card-plan_bg-violet {
    background: rgba(63, 68, 196, 0.1);
    min-height: 37px !important;
    padding: 0 4px !important;
}

.w-100 {
    width: 100% !important;
}

.btn-phone,
.btn-favorite {
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.btn-phone {
    position: absolute;
    right: 0;
    top: 7px;
    background: #fff !important;
}

.btn-percent {
    position: absolute;
    background: var(--green_color-base) !important;
    bottom: 10px;
    right: 0;
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.color-icon-white {
    color: white !important;
}
</style>
