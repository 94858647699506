<template>
    <v-card class="mb-5 text-left" elevation="0">
        <v-card-title class="pb-0" v-if="comment_data.user">{{comment_data.user.name}}</v-card-title>
        <v-card-title class="main_color_text mt-n2 pt-0 font-weight-thin">{{ new Date(comment_data.created_at).toLocaleString() }}
        </v-card-title>
        <v-rating
            class="ml-2 pl-2 mb-2"
            v-model="comment_data.rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$ratingFull"
            half-increments hover large readonly
        >{{comment_data.rating}}</v-rating>
        <v-card-text v-if="comment_data.text" class="fz-18 lh-22 pb-8">
            {{comment_data.text}}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "comments-card",
    props: ['comment_data'],
    data: function () {
        return {
            rating: 4.5,
        }
    }
}
</script>

<style scoped>

</style>
