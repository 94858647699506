<template>
    <v-app-bar app color="primary" clipped-left style="z-index: 200;" class="overflow-hidden">
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')" v-if="isDashboard" />

        <v-container>
            <v-row d-flex align="center" class="flex-nowrap">
                <v-col cols="2" class="pl-0 logo-xs">
                    <router-link :to="{ name: 'home' }" class="link-logo">
                        <div class="d-flex align-center">
                            <v-img :alt="appName + 'logo'" class="shrink mr-2 logo-top-menu max-height100" contain
                                :src="require('../assets/img/logo.png')" transition="scale-transition"
                                :width="$vuetify.breakpoint.width > 900 ? 180 : 160" />
                        </div>
                    </router-link>
                </v-col>

                <v-col sm="5" md="7" v-if="$vuetify.breakpoint.width > 900" class="d-flex justify-space-around pr-0">
                    <v-btn plain :to="{ name: 'projects.index' }" class=" main_color_text" depressed>
                        {{ $t('menu.projects') }}
                    </v-btn>
                    <v-btn plain :to="{ name: 'banks.index' }" class=" main_color_text" depressed>
                        {{ $t('menu.banks') }}
                    </v-btn>
                    <v-btn plain :to="{ name: 'developers.index' }" class="main_color_text" depressed>
                        {{ $t('menu.developers') }}
                    </v-btn>
                    <v-btn plain :to="{ name: 'blogs.index' }" class=" main_color_text" depressed>
                        {{ $t('menu.blogs') }}
                    </v-btn>
                    <v-btn plain :to="{ name: 'cities.index' }" class=" main_color_text" depressed>
                        {{ $t('menu.cities') }}
                    </v-btn>
                </v-col>

                <v-col v-else xs="8" sm="8" md="4" lg="2" xl="2" cols="12"
                    class="d-flex justify-center icon-account-xs">
                    <a href="#" class="text-decoration-none">
                        <v-icon class="btn__account-circle" large>mdi-account-circle-outline</v-icon>
                    </a>
                </v-col>

                <v-col cols="3"
                    class="text-right d-flex align-center justify-end icon-burger-xs pa-0">
                    <v-row class="top-menu">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-if="$vuetify.breakpoint.width > 1240">
                                    <waiting-call-dialog :btn_location="'header'" class="mr-0" />
                                </div>
                                <v-btn v-bind="attrs" v-on="on">
                                    {{ $i18n.locale }}
                                </v-btn>
                            </template>
                            <v-list class="text-center">
                                <v-list-item v-for="locale in locales" :key="locale">
                                    <v-list-item-title>
                                        <a class="lang-item" :href="getUrlFromWithLocale(locale)">
                                            {{ locale.toUpperCase() }}
                                        </a>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-row>
                    <div v-if="$vuetify.breakpoint.width > 1240">
                        <auth-user-dropdown v-if="$auth.user()" class="px-0" />
                        <auth-dialog show_form_login="true" v-else>
                            <template #activator="{ on, attrs }">
                                <v-btn depressed class="main_color_text" plain v-bind="attrs" v-on="on">
                                    {{ $t('auth.login') }}
                                </v-btn>
                            </template>
                        </auth-dialog>
                        <!--                    <v-btn text :to="{name: 'login'}" v-else class="main_color_text">{{ $t('auth.login') }}</v-btn>-->
                    </div>
                    <div v-else>
                        <auth-user-dropdown v-if="$auth.user()" class="px-0" />
                        <auth-dialog show_form_login="true" v-else>
                            <template #activator="{ on, attrs }">
                                <v-btn depressed class="main_color_text" plain v-bind="attrs" v-on="on">
                                    {{ $t('auth.login') }}
                                </v-btn>
                            </template>
                        </auth-dialog>
                        <!--                    <v-btn text :to="{name: 'login'}" v-else class="main_color_text">{{ $t('auth.login') }}</v-btn>-->
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
import AuthUserDropdown from "./AuthUserDropdown";
import WaitingCallDialog from "@/components/dialogs/WaitingCallDialog";
import AuthDialog from "@/components/dialogs/AuthDialog";

export default {
    name: "top-menu",
    components: { AuthUserDropdown, WaitingCallDialog, AuthDialog },
    props: {
        isDashboard: {
            default: false,
            type: Boolean,
        }
    },
    computed: {
        appName() {
            return process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "GETHOME"
        },
    },
    data() {
        return {
            locales: ['en', 'hy', 'ru'],
        }
    },
    methods: {
        getUrlFromWithLocale(locale) {
            const url = new URL(window.location.href)
            let hostname = url.hostname;
            let urlArray = hostname.split('.')
            const port = process.env.NODE_ENV !== 'production' ? `:${window.location.port}` : '';

            if (this.locales.includes(urlArray[0])) {
                hostname = hostname.slice(3)
            }

            return locale === 'hy'
                ? `${url.protocol}//${hostname}${port}${this.$route.fullPath}`
                : `${url.protocol}//${locale}.${hostname}${port}${this.$route.fullPath}`
        },
    },
    mounted() {
        this.$store.commit("site/updateWidth_site");
        window.addEventListener("resize", () => this.$store.commit("site/updateWidth_site"));
    }
}
</script>

<style lang="scss" scoped>
.logo-xs,
.icon-account-xs,
.icon-burger-xs {
    @media all and (max-width: 770px) {
        max-width: 47% !important;
    }
}

#logo-name,
#logo-name-subtitle {
    text-decoration: none;
    color: var(--main_color-base);
}

.logo-top-menu {
    min-width: 20px;
}

@media all and (max-width: 1240px) {
    #logo-name {
        font-size: 18px !important;
        white-space: nowrap;
    }

    #logo-name-subtitle {
        font-size: 12px !important;
        white-space: nowrap;
    }
}

#logo-name-subtitle {
    font-weight: 400;
}

.link-logo {
    text-decoration: none;
}

.p-relative {
    position: relative;

    .locale-select {
        position: absolute;
        right: -43px;
        top: 4px;
        color: #0d47a1;
        padding: 0 5px;
        border-radius: 5px;
        text-align: center;
        font-size: 12px !important;

        &:active,
        &:focus {
            border: none;
            outline: none;
        }
    }
}

.mx-width33 {
    @media all and (max-width: 900px) {
        max-width: 23%;
    }
}

#border-btn {
    border: 1px solid var(--main_color-base) !important;
    border-radius: 0;

    @media all and (max-width: 900px) {
        border: none !important;
        max-width: 36px;
        min-width: 36px;
        width: 36px;
        padding: 0;
        overflow: hidden;
    }
}

.max-height100 {
    max-height: 90%;
}

.lang-item {
    text-decoration: none;
    color: inherit;
}

.top-menu {
    justify-content: space-evenly;
    margin-right: 10px;
}

@media screen and (max-width: 1240px) {
    .top-menu {
        margin-right: 0;
    }
}


@media screen and (max-width: 1322px) {
    .top-menu {
        margin-right: 0;
        flex: 1 0 auto;
        justify-content: end;
    }
}



@media screen and (max-width: 900px) {
    .top-menu {
        width: 0px;
    }

    .col-sm-8 {
        flex: 0 0 57.0%;
        max-width: 57.0%;
    }
}
</style>
